import { Fragment, useState, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'
import Link from 'next/link'

import { useBasket } from '../../providers/basket'

export default function AddedItem() {
  const { notify, hideNotify } = useBasket()
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (notify) {
      setShow(true)
      setTimeout(() => {
        setShow(false)
        hideNotify()
      }, 1000 * 10)
    }
  }, [notify])

  if (!show || location.pathname.includes('booking')) {
    return null
  }

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <div className="flex">
                <CheckCircleIcon
                  className="h-6 w-6 text-brand-600 mr-3"
                  aria-hidden="true"
                />
                <p className="text-base font-medium text-gray-900">
                  Item added to your basket
                </p>
              </div>
              <div className="mt-3">
                <Link href="/booking/" passHref className="btn-primary">
                  Checkout
                </Link>
              </div>
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  setShow(false)
                  hideNotify()
                }}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
